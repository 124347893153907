import { graphql } from "gatsby"
import React from "react"
import propTypes from "prop-types"
import windowSize from "react-window-size"

import Layout from "../../layout"
import Metaball from "../../components/metaball"
import QuickLinks from "./quick-links"
import HeroContainer from "../../components/containers/hero"
import ContentBlock from "../../components/content-block"
import Title from "../../components/hero/title"
import Subtitle from "../../components/hero/subtitle"
import LinkToParentPage from "../../components/link-to-parent-page"
import PullBelowHeader from "../../components/containers/pull-below-header"

import { rhythm } from "../../utils/typography"
import {
  guideContentBlockStyles,
  guideContainer,
  guidePaddingBottom,
  anchorStyles,
} from "../../utils/styles"

export { ContentfulHead as Head } from "../../head"

const styles = theme => ({
  container: {
    overflowX: `hidden`,
    position: `relative`,
    zIndex: 2,
  },
  contentBlock: guideContentBlockStyles(theme),
  partnerLogo: {
    height: `auto`,
    left: -16,
    position: `absolute`,
    top: 200,
    width: 240,
    transition: `opacity ${theme.transitions.speed.default} ${theme.transitions.curve.default}`,
    "@media (max-width: 1440px)": {
      opacity: `0.15`,
    },
    "@media (max-width: 750px)": {
      display: `none`,
    },
    "& img": {
      width: `100%`,
    },
  },
})

class DefaultGuidePage extends React.Component {
  render() {
    const { contentfulPage: page } = this.props.data
    const {
      backgroundColor,
      contentBlocks,
      keyvisual,
      quickLinks,
      subtitle,
      title,
    } = page

    return (
      <Layout pathname={this.props.location.pathname}>
        <PullBelowHeader>
          <div
            css={theme => [styles(theme).container, guidePaddingBottom(theme)]}
          >
            <Metaball css={{ color: backgroundColor }} right />
            <HeroContainer isInverted>
              <div css={theme => guideContainer(theme)}>
                {page.parentPage && (
                  /*
                  Hardcoding the backlink because we need to return to a non-ContentfulPage.
                  The guide list is now built using SSLP: https://app.contentful.com/spaces/vkdbses00qqt/entries/5SjKWDRPEG333CEt0VUPCr
                  */
                  <LinkToParentPage link="/guides/all" title="Gatsby Guides" />
                )}
                {!!keyvisual && (
                  <div css={theme => styles(theme).partnerLogo}>
                    <img
                      src={keyvisual.asset.file.url}
                      alt={`${keyvisual.name} Logo`}
                    />
                  </div>
                )}
                <Title
                  css={theme => ({
                    maxWidth: rhythm(16),
                    marginBottom: theme.space[13],
                    [theme.mediaQueries.phablet]: {
                      maxWidth: rhythm(20),
                    },
                  })}
                >
                  {title}
                </Title>
                <Subtitle
                  css={theme => ({
                    maxWidth: rhythm(18),
                    [theme.mediaQueries.phablet]: {
                      maxWidth: rhythm(22),
                    },
                  })}
                >
                  {subtitle.subtitle}
                </Subtitle>
              </div>
            </HeroContainer>
            {quickLinks && !!quickLinks.length && (
              <QuickLinks quickLinks={quickLinks} />
            )}
            <div css={theme => [anchorStyles(theme), guideContainer(theme)]}>
              {contentBlocks.map((cb, idx) => (
                <div key={idx} css={theme => styles(theme).contentBlock}>
                  <ContentBlock contentBlock={cb} />
                </div>
              ))}
            </div>
          </div>
        </PullBelowHeader>
      </Layout>
    )
  }
}

DefaultGuidePage.propTypes = {
  data: propTypes.object.isRequired,
  location: propTypes.object.isRequired,
}

export default windowSize(DefaultGuidePage)

export const pageQuery = graphql`
  query GuidePage($id: String!) {
    contentfulPage(id: { eq: $id }) {
      name
      title
      subtitle {
        subtitle
      }
      backgroundColor
      keyvisual {
        name
        asset {
          file {
            url
          }
        }
      }
      parentPage {
        name
        slug
        parentPage {
          name
          slug
        }
      }
      quickLinks {
        displayTitle
        url
      }
      ...ContentBlocks
      ...SocialMediaImage
    }
  }
`
